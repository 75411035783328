import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
const functions = require("../util/functions");
const clone = require('rfdc')();

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LanguageDD(props) {
  return (
    <Menu as="div" className="relative z-30 mr-4">
      <div className="">
        <Menu.Button className="text-gray-800 inline-flex justify-center items-center rounded-md px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50 focus:outline-none border border-black/50 md:text-base font-medium worksans">
          <span className="text-black">Language</span>
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition-all ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-40 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-2 px-2 flex flex-col items-start">
              {(props.languages ?? []).map((item, i)=>{
                  return (
                    <Menu.Item key={i}>
                    {({ active }) => (
                      <a
                        
                        className={classNames(
                          active ? "hover:text-[#ff4500]" : "",
                          "m-1 text-xs text-gray-800 cursor-pointer"
                        )}
                        onClick={() => {
                          let f = clone(props.filters);
                          if (f.languages.includes(item.name)) {
                            let i = f.languages.indexOf(item.name);
                            f.languages.splice(i, 1);
                          } else {
                            f.languages.push(item.name);
                          }
                          props.setfilters({ ...f });
                        }}
                      >
                        {functions.capitalize(item.name)}
                      </a>
                    )}
                  </Menu.Item>
                  )
              })}
            
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
