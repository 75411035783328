import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import AstroCard from "../components/AstroCard";
import Header from "../components/Header";
import { AuthContext } from "../context/Auth";
import useWidgets from "../hooks/useWidgets";
import { ConsultationContext } from "../context/Consultation";
import Footer from "../components/Footer";
import mag from "../staticAssets/mag.png";
import { AnalyticsContext } from "../context/Analytics";
import SortDD from "../components/SortDD";
import SkillsDD from "../components/SkillsDD";
import PriceDD from "../components/PriceDD";
import LanguageDD from "../components/LanguageDD";
import { AppConstantsContext } from "../context/AppConstants";
const clone = require("rfdc")();
const functions = require("../util/functions");
const limit = 20;
const totalTopBanners = 3;
const emptyFilters = {
  rating: "",
  specialization: [],
  languages: [],
  price: "",
};

const bns = [
  {text1: "Get 300% extra", text2: "on first recharge", code:"1FOR4"},
  {text1: "Get 100% EXTRA", text2: "on next call", code:"LOVE"},
  {text1: "30% more time", text2: "with your Bodhi", code:"SAVE30"},
];

const usdbns = [
  {text1: "Get $4 extra", text2: "on first recharge", code:"FREE"},
  {text1: "Get 20% more cash", text2: "with your Bodhi", code:"SAVE20"},
]

// markup
const IndexPage = ({location}) => {
  const [page, setpage] = useState(0);
  const [host, sethost] = useState("");
  const [category, setcategory] = useState("all");
  const [bI, setbI] = useState(0);
  const [msg, setmsg] = useState("")

  const [showFilters, setshowFilters] = useState(false);
  const [currentFil, setcurrentFil] = useState({ ...emptyFilters });
  const defaultLanguages = new URLSearchParams(location.search).get('lang');

  const {
    isLoading,
    error,
    widgets,
    hasMore,
    astrologerCount,
    totalConsultations,
    setStatus,
    sort,
    setsort,
    filters,
    setfilters,
    resetFilters,
  } = useWidgets(category, page, limit, setpage, defaultLanguages);
  const appConstants = useContext(AppConstantsContext) || {};
  const { user, fetchUser } = useContext(AuthContext) || {
    user: {},
    fetchUser: () => {},
  };
  const { astrologerId } = useContext(ConsultationContext) || {
    astrologerId: "",
  };

  const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };

  useEffect(() => {
    gtmPageViewEvent(window.location.pathname, window.location.host);
  }, []);

  const nextBI = () => {
    setbI((bI + 1) % (user?.currencyType=="usd"? usdbns.length: bns.length));
  };

  const changeCurrRating = (e) => {
    let f = clone(currentFil);
    f.rating = e.target.value;
    setcurrentFil({ ...f });
  };
  const changeCurrLanguage = (e) => {
    let f = clone(currentFil);
    if (f.languages.includes(e.target.value)) {
      let i = f.languages.indexOf(e.target.value);
      f.languages.splice(i, 1);
    } else {
      f.languages.push(e.target.value);
    }
    setcurrentFil({ ...f });
  };

  const changeCurrSpecialization = (e) => {
    let f = clone(currentFil);
    if (f.specialization.includes(e.target.value)) {
      let i = f.specialization.indexOf(e.target.value);
      f.specialization.splice(i, 1);
    } else {
      f.specialization.push(e.target.value);
    }
    setcurrentFil({ ...f });
  };
  const changeCurrPrice = (e) => {
    let f = clone(currentFil);
    f.price = e.target.value;
    setcurrentFil({ ...f });
  };

  const currency = () => {
    if (user?.currencyType == "usd") {
      return "$";
    } else {
      return "₹";
    }
  };

  const changeRating = (e) => {
    let f = clone(filters);
    f.rating = e.target.value;
    setfilters({ ...f });
  };
  const changeLanguage = (e) => {
    let f = clone(filters);
    if (f.languages.includes(e.target.value)) {
      let i = f.languages.indexOf(e.target.value);
      f.languages.splice(i, 1);
    } else {
      f.languages.push(e.target.value);
    }
    setfilters({ ...f });
  };

  const changeSpecialization = (e) => {
    let f = clone(filters);
    if (f.specialization.includes(e.target.value)) {
      let i = f.specialization.indexOf(e.target.value);
      f.specialization.splice(i, 1);
    } else {
      f.specialization.push(e.target.value);
    }
    setfilters({ ...f });
  };
  const changePrice = (e) => {
    let f = clone(filters);
    f.price = e.target.value;
    setfilters({ ...f });
  };

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setpage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );
  useEffect(() => {
    sethost(window.location.host);
    fetchUser();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 756) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide">
      {showFilters &&<div
        className={`${
          showFilters
            ? "opacity-100 z-50 duration-100 h-[100vh] w-full bg-black/80"
            : "opacity-0 -z-50 duration-300 h-0 w-0 bg-transparent"
        } transition-all transform ease-in-out fixed top-0 w-screen flex flex-col justify-end`}
      >
        <div className="flex w-full justify-center mb-4">
          <button
            onClick={() => {
              setshowFilters(false);
              let fil = clone(filters);
              setcurrentFil({ ...fil });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 drop-shadow-md drop-shadow-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#ED9108"
              strokeWidth={1}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        <div
          className={`${
            showFilters
              ? "translate-y-0 duration-300"
              : "translate-y-full duration-100"
          } ease-in-out transition-all transform bg-white rounded-t-2xl h-[90vh] flex flex-col overflow-y-scroll w-screen relative`}
        >
          <div className="fixed bottom-1 w-full flex justify-center">
            <button
              className="w-10/12 h-12 bg-gradient-to-r to-[#FB5B1D] from-orange-500 rounded-xl text-white worksans"
              onClick={() => {
                let cfil = clone(currentFil);
                setfilters({ ...cfil });
                setshowFilters(false);
              }}
            >
              Apply Filters
            </button>
          </div>
          <a
            className="absolute top-3 right-4 text-[#FB5B1D] cursor-pointer worksans"
            onClick={() => {
              resetFilters();
              setshowFilters(false);
            }}
          >
            Reset
          </a>
          <div className="ml-4 worksans mt-6">
            <h4 className="my-2 text-black text-orange-500">Ratings</h4>
            <div className="flex flex-wrap gap-4">
              
              <div className="flex gap-1 my-0.5">
                <input
                  type="checkbox"
                  className="accent-gray-500"
                  id="r-22"
                  value="4+"
                  checked={currentFil.rating == "4+"}
                  onChange={changeCurrRating}
                />
                <label htmlFor="r-22" className="cursor-pointer">
                  4+
                </label>
              </div>
              <div className="flex gap-1 my-0.5">
                <input
                  type="checkbox"
                  className="accent-gray-500"
                  id="r-32"
                  value="3+"
                  checked={currentFil.rating == "3+"}
                  onChange={changeCurrRating}
                />
                <label htmlFor="r-32" className="cursor-pointer">
                  3+
                </label>
              </div>
              <div className="flex gap-1 my-0.5">
                <input
                  type="checkbox"
                  className="accent-gray-500"
                  id="r-42"
                  value="2+"
                  checked={currentFil.rating == "2+"}
                  onChange={changeCurrRating}
                />
                <label htmlFor="r-42" className="cursor-pointer">
                  2+
                </label>
              </div>
              <div className="flex gap-1 my-0.5">
                <input
                  type="checkbox"
                  className="accent-gray-500"
                  id="r-52"
                  value="1+"
                  checked={currentFil.rating == "1+"}
                  onChange={changeCurrRating}
                />
                <label htmlFor="r-52" className="cursor-pointer">
                  1+
                </label>
              </div>
            </div>
            <div className="h-0.5 w-full bg-gray-400/20 my-2 rounded-full"></div>
            <h4 className="my-2 text-black text-orange-500">Skills</h4>
            <div className="flex flex-wrap gap-x-4 gap-y-2">
              {(appConstants?.specializations ?? []).map((l, i) => {
                return (
                  <div className="flex gap-1 my-0.5" key={i}>
                    <input
                      type="checkbox"
                      checked={currentFil.specialization.includes(l.title)}
                      className="accent-gray-500"
                      id={`s-${i}`}
                      onChange={changeCurrSpecialization}
                      value={l.title}
                    />
                    <label htmlFor={`s-${i}`} className="cursor-pointer">
                      {functions.capitalizeFirstLetter(l.title)}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="h-0.5 w-full bg-gray-400/20 my-2 rounded-full"></div>
            <h4 className="my-2 text-black text-orange-500">Language</h4>
            <div className="flex flex-wrap gap-x-4 gap-y-2">
              {(appConstants?.languages ?? []).map((l, i) => {
                return (
                  <div className="flex gap-1 my-0.5" key={i}>
                    <input
                      type="checkbox"
                      checked={currentFil.languages.includes(l.name)}
                      className="accent-gray-500"
                      id={`l-${i}`}
                      onChange={changeCurrLanguage}
                      value={l.name}
                    />
                    <label htmlFor={`l-${i}`} className="cursor-pointer">
                      {functions.capitalize(l.name)}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="h-0.5 w-full bg-gray-400/20 my-2 rounded-full"></div>
            <h4 className="my-2 text-black text-orange-500">Price</h4>
            <div className="flex flex-wrap gap-x-4 gap-y-2">
              <div className="flex gap-1 my-0.5">
                <input
                  type="checkbox"
                  className="accent-gray-500"
                  id="p-12"
                  value="-20"
                  onChange={changeCurrPrice}
                  checked={currentFil.price == "-20"}
                />
                <label htmlFor="p-12" className="cursor-pointer">
                  &gt; {currency()} 20
                </label>
              </div>
              <div className="flex gap-1 my-0.5">
                <input
                  type="checkbox"
                  className="accent-gray-500"
                  id="p-22"
                  value="20-40"
                  onChange={changeCurrPrice}
                  checked={currentFil.price == "20-40"}
                />
                <label htmlFor="p-22" className="cursor-pointer">
                  {currency()} 20 - {currency()} 40
                </label>
              </div>
              <div className="flex gap-1 my-0.5">
                <input
                  type="checkbox"
                  className="accent-gray-500"
                  id="p-32"
                  value="40-60"
                  onChange={changeCurrPrice}
                  checked={currentFil.price == "40-60"}
                />
                <label htmlFor="p-32" className="cursor-pointer">
                  {currency()} 40 - {currency()} 60
                </label>
              </div>
              <div className="flex gap-1 my-0.5">
                <input
                  type="checkbox"
                  className="accent-gray-500"
                  id="p-42"
                  value="60+"
                  onChange={changeCurrPrice}
                  checked={currentFil.price == "60+"}
                />
                <label htmlFor="p-42" className="cursor-pointer">
                  {currency()} 60 &lt;
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>}
      
        <Header />
        <div className="flex-1 overflow-y-auto flex flex-col z-0">
          <main className="grow hide-scroll-bar scrollbar-hide">
            <div className="w-full relative bg-[#FFEEEF] overflow-hidden h-32 md:h-48">
              <button
                className="absolute top-1/3 right-1 md:right-10 w-8 h-8 md:w-12 md:h-12 flex justify-center items-center rounded-full bg-white shadow-xl z-20 opacity-70"
                onClick={() => {
                  nextBI();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 md:h-6 w-4 md:w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
              {/* <img className="h-32 md:h-auto object-cover" src={bnbg} /> */}
              {new Array(totalTopBanners).fill(2).map((v, i) => {
                return (
                  <div
                    key={i}
                    className={`${
                      bI == i
                        ? "opacity-100 translate-x-0 bg-transparent"
                        : bI > i
                        ? "opacity-0 -translate-x-full"
                        : "opacity-0 translate-x-full"
                    } transition-all transform duration-1000 ease-in absolute top-0 w-screen h-full flex justify-center items-center snap-center bg-[url('../staticAssets/bnbg.png')]`}
                  >
                    <img loading="lazy" src={mag} className="object-contain h-32 md:h-48" />
                    <div className="flex flex-col ml-2 md:ml-10 mr-2">
                      <span className="text-[20px] md:text-[45px] tracking-wide text-[#28214B] font-medium">
                        <h4 className="font-bold inline">{user?.currencyType == "usd"? usdbns[i]?.text1:bns[i]?.text1}</h4> {user?.currencyType == "usd"?usdbns[i]?.text2:bns[i]?.text2}
                      </span>
                      <button className="mt-2 py-2 px-4 bg-white rounded-lg border border-dashed border-[#ff5a00] w-fit text-[#ff5a00] font-semibold text-sm md:text-base" onClick={() => { navigator.clipboard.writeText(bns[i].code); setmsg("Copied ✅"); setTimeout(() =>{setmsg("")}, 3000)}}>
                        Promo Code: {user?.currencyType == "usd"? usdbns[i]?.code : bns[i]?.code}
                      </button>
                      <h6 className={`text-xs text-back raleway font-semibold m-1 ${msg? "visible": "invisible"}`}>{msg || "jnkj"}</h6>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="md:w-0 visible md:invisible w-full shadow-xl flex md:h-0 h-16 items-center pl-2 z-10 relative">
              <SkillsDD
                skills={appConstants?.specializations}
                setfilters={setfilters}
                filters={filters}
              />
              <PriceDD currencyType={user?.currencyType} setfilters={setfilters}
                filters={filters}/>
              <LanguageDD
                languages={appConstants?.languages}
                setfilters={setfilters}
                filters={filters}
              />
              <button
                onClick={() => {
                  let f = clone(filters);
                  setcurrentFil({ ...f });
                  setshowFilters(true);
                }}
                className="absolute right-0 h-12 w-10 bg-gradient-to-r from-[#FEB458] to-[#ED9108] rounded-l-lg flex items-center justify-center z-40"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#ffffff"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  />
                </svg>
              </button>
            </div>
            <div className="flex gap-0 md:gap-4 worksans mb-20">
              <div className="border-r flex flex-col pl-0 md:pl-6 pr-0 md:pr-3 w-0 md:w-1/4 invisible md:visible">
                <div className="flex items-center justify-between">
                  <h2 className="my-4 font-semibold text-lg">Select Filters</h2>
                  <a className="text-[#FB5B1D] cursor-pointer text-sm" onClick={()=>resetFilters()}>Reset</a>
                  </div>
                <h4 className="my-2">Ratings</h4>
                
                <div className="flex gap-4 my-0.5">
                  <input
                    type="checkbox"
                    className="accent-gray-500"
                    id="r-2"
                    value="4+"
                    checked={filters.rating == "4+"}
                    onChange={changeRating}
                  />
                  <label htmlFor="r-2" className="cursor-pointer">
                    4 & above
                  </label>
                </div>
                <div className="flex gap-4 my-0.5">
                  <input
                    type="checkbox"
                    className="accent-gray-500"
                    id="r-3"
                    value="3+"
                    checked={filters.rating == "3+"}
                    onChange={changeRating}
                  />
                  <label htmlFor="r-3" className="cursor-pointer">
                    3 & above
                  </label>
                </div>
                <div className="flex gap-4 my-0.5">
                  <input
                    type="checkbox"
                    id="r-4"
                    className="accent-gray-500"
                    value="2+"
                    checked={filters.rating == "2+"}
                    onChange={changeRating}
                  />
                  <label htmlFor="r-4" className="cursor-pointer">
                    2 & above
                  </label>
                </div>
                <div className="flex gap-4 my-0.5">
                  <input
                    type="checkbox"
                    id="r-5"
                    className="accent-gray-500"
                    value="1+"
                    checked={filters.rating == "1+"}
                    onChange={changeRating}
                  />
                  <label htmlFor="r-5" className="cursor-pointer">
                    1 & above
                  </label>
                </div>
                <div className="h-0.5 w-full bg-gray-400/20 my-6 rounded-full"></div>
                <h4 className="my-2">Skills</h4>
                {(appConstants?.specializations ?? []).map((l, i) => {
                  return (
                    <div className="flex gap-1 my-0.5" key={i}>
                      <input
                        type="checkbox"
                        checked={filters.specialization.includes(l.title)}
                        className="accent-gray-500"
                        id={`ss-${i}`}
                        onChange={changeSpecialization}
                        value={l.title}
                      />
                      <label htmlFor={`ss-${i}`} className="cursor-pointer">
                        {functions.capitalizeFirstLetter(l.title)}
                      </label>
                    </div>
                  );
                })}

                <div className="h-0.5 w-full bg-gray-400/20 my-6 rounded-full"></div>
                <h4 className="my-2">Language</h4>
                {(appConstants?.languages ?? []).map((l, i) => {
                  return (
                    <div className="flex gap-1 my-0.5" key={i}>
                      <input
                        type="checkbox"
                        checked={filters.languages.includes(l.name)}
                        className="accent-gray-500"
                        id={`ll-${i}`}
                        onChange={changeLanguage}
                        value={l.name}
                      />
                      <label htmlFor={`ll-${i}`} className="cursor-pointer">
                        {functions.capitalize(l.name)}
                      </label>
                    </div>
                  );
                })}

                <div className="h-0.5 w-full bg-gray-400/20 my-6 rounded-full"></div>
                <h4 className="my-2">Price</h4>
                <div className="flex gap-4 my-0.5">
                  <input
                    type="checkbox"
                    className="accent-gray-500"
                    id="p-1"
                    value="-20"
                    onChange={changePrice}
                    checked={filters.price == "-20"}
                  />
                  <label htmlFor="p-1" className="cursor-pointer">
                    &lt; {currency()} 20
                  </label>
                </div>
                <div className="flex gap-4 my-0.5">
                  <input
                    type="checkbox"
                    className="accent-gray-500"
                    id="p-2"
                    value="20-40"
                    onChange={changePrice}
                    checked={filters.price == "20-40"}
                  />
                  <label htmlFor="p-2" className="cursor-pointer">
                    {currency()} 20 - {currency()} 40
                  </label>
                </div>
                <div className="flex gap-4 my-0.5">
                  <input
                    type="checkbox"
                    className="accent-gray-500"
                    id="p-3"
                    value="40-60"
                    onChange={changePrice}
                    checked={filters.price == "40-60"}
                  />
                  <label htmlFor="p-3" className="cursor-pointer">
                    {currency()} 40 - {currency()} 60
                  </label>
                </div>
                <div className="flex gap-4 my-0.5">
                  <input
                    type="checkbox"
                    className="accent-gray-500"
                    id="p-4"
                    value="60+"
                    onChange={changePrice}
                    checked={filters.price == "60+"}
                  />
                  <label htmlFor="p-4" className="cursor-pointer">
                    &gt; {currency()} 60
                  </label>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex w-full justify-between items-center">
                  <h3 className="m-1 md:m-4 text-sm lg:text-xl tracking-tight md:tracking-normal">
                    {"Showing " + astrologerCount + " Astrologers"}
                  </h3>
                  <SortDD sort={sort} setsort={setsort} />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center">
                  {widgets?.map((v, i) => {
                    if (widgets.length === i + 1) {
                      return (
                        <div className="" key={i} ref={lastElementRef}>
                          <AstroCard
                            data={v}
                            astrologerId={astrologerId}
                            setStatus={setStatus}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <AstroCard
                          key={i}
                          data={v}
                          astrologerId={astrologerId}
                          setStatus={setStatus}
                        />
                      );
                    }
                  })}
                  {isLoading && (
                    <div className="border border-gray-100  rounded-md p-4 max-w-sm w-11/12 m-4">
                      <div className="animate-pulse flex flex-row lg:flex-col space-x-4">
                        <div className="w-1/3 lg:w-full flex justify-center">
                          <div className="rounded-full bg-gray-200 h-32 lg:h-44 w-32 lg:w-44"></div>
                        </div>
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-gray-200 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-gray-200 rounded"></div>
                          </div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-gray-200 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {widgets.length == 0 && !isLoading && (
                  <div className="flex flex-col items-center w-full">
                    <h6 className="text-center mt-10">No Astrologers Found</h6>
                    <button
                      className="rounded px-4 py-1 border text-sm mt-4"
                      onClick={() => {
                        resetFilters();
                      }}
                    >
                      Reset Filters
                    </button>
                  </div>
                )}
              </div>
            </div>
          </main>
          {/* {host != "" &&
            (host != "astroguru.bodhiness.com" ||
              host != "mobafl.bodhiness.com") && (
              <div className="container mx-auto flex justify-center my-8">
                <ContactForm />
              </div>
            )} */}
          {host != "" &&
            (host != "astroguru.bodhiness.com" ||
              host != "mobafl.bodhiness.com" ||
              host != "webj.bodhiness.com" || host != "lokal.bodhiness.com" || host != "namah.bodhiness.com") && <Footer />}
        </div>
      </div>
    
  );
};

export default IndexPage;
