import React, { useContext} from "react";
import { navigate } from "gatsby";
import { processLanguage } from "../util/functions";
import { ConsultationContext } from "../context/Consultation";
import { AuthContext } from "../context/Auth";
import { AnalyticsContext } from "../context/Analytics";

import i from "../staticAssets/user.png";

function AstroCard({ data, astrologerId, setStatus }) {
  const { initiateCall, initiateChat } = useContext(
    ConsultationContext
  ) || { initiateCall: () => {}, initiateChat: () => {}};
  const { user, setshowLogin } = useContext(AuthContext) || {
    user: {},
    setshowLogin: () => {},
  };
  const { gtmCustomEvent } = useContext(AnalyticsContext) || {
    getCustomEvent: () => {},
  };

  return (
    <>
      {data?.widgetType === 1 && (data?.data?.isConnectModeChat || data?.data?.isConnectModeCall)? (
        <div className="flex justify-center border mb-4 rounded-xl mx-4 worksans">
          <div className="w-11/12 lg:w-10/12 flex flex-row lg:flex-col my-4">
            <button
              className="flex items-start"
              onClick={() => {
                navigate("/astrologers/" + data?.data?.user, {
                  state: data?.data?.user,
                });
              }}
            >
              <div className="h-28 w-28 lg:h-48 lg:w-full bg-gray-100/50 rounded-xl lg:flex lg:justify-center overflow-hidden relative">
                {data?.data?.imgUrl ? (
                  <img
                    loading="lazy"
                    src={data?.data?.imgUrl}
                    className="object-contain h-28 w-28 lg:h-48 lg:w-48"
                    alt={data?.data?.name}
                    title={data?.data?.name}
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={i}
                    className="object-contain h-28 w-28 lg:h-48 lg:w-48"
                    alt=""
                  />
                )}
                <div className="absolute bottom-1 md:bottom-auto md:top-2 right-1 md:right-2 border border-[#ff7000] rounded-xl p-1 flex items-center bg-white shadow-md">
                    <h5 className="text-xs text-[#ff7000] flex items-center justify-between">
                      {data?.data?.rating}{" "}
                    </h5>
                    <svg
                      className="ml-1"
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5 0.5L6.73494 4.30026L10.7308 4.30041L7.49817 6.64924L8.73282 10.4496L5.5 8.101L2.26718 10.4496L3.50183 6.64924L0.269189 4.30041L4.26506 4.30026L5.5 0.5Z"
                        fill="#F98A28"
                      />
                    </svg>
                  </div>
              </div>
            </button>
            <div className="flex-1 ml-2 overflow-hidden truncate">
              <button
                className="flex flex-col items-start w-full"
                onClick={() => {
                  navigate("/astrologers/" + data?.data?.user, {
                    state: data?.data?.user,
                  });
                }}
              >
                <div className="flex justify-between items-center mb-1 w-full lg:mt-2">
                  <h3 className="text-black lg:text-lg">
                    {data?.data?.name}{" "}
                  </h3>
                  
                </div>
                <div>
                  <h4 className="text-sm lg:text-base">
                    {"Exp - " +
                      (data?.data?.experience?.years ?? "1") +
                      " years+"}
                  </h4>
                </div>
                <div>
                  <h4 className="text-sm lg:text-base truncate">
                    {data?.data?.subHeading}
                  </h4>
                </div>
                <div className="mb-3">
                  <h4 className="text-sm lg:text-base truncate">
                    {processLanguage(data?.data?.languages)}
                  </h4>
                </div>
              </button>
              <div className="flex justify-between w-full">
                {data?.data?.isConnectModeCall && (data?.data?.user === astrologerId ? (
                  <button
                    disabled
                    className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-sm shadow object-contain p-1"
                  >
                    <div className="flex flex-col items-center">
                      <h2 className="p-2">Busy</h2>
                    </div>
                  </button>
                ) : data?.data?.status == 1 ? (
                  <button
                    className="bg-[#87BD36] w-full rounded-xl text-white text-xs lg:text-sm shadow object-contain p-1"
                    onClick={async() => {
                      if (user?.name !== undefined) {
                        gtmCustomEvent("call_chat");
                        let s = await initiateCall(data?.data?.user, data?.data?.imgUrl);
                        if (s != 1) {
                          setStatus(data?.data?.user, s);
                        }
                      } else {
                        setshowLogin(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center">
                      <h4>Call</h4>
                      <div className="flex">
                        <h4 className="line-through">
                          {(user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.actualCallRate?.usd
                              : data?.data?.actualCallRate?.inr) +
                            ","}
                        </h4>{" "}
                        <h4 className="ml-1">
                          {(user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.callRate?.usd
                              : data?.data?.callRate?.inr) +
                            "/Min"}
                        </h4>
                      </div>
                    </div>
                  </button>
                ) : data?.data?.status == 2 ? (
                  <button
                    disabled
                    className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-sm shadow object-contain p-1"
                  >
                    <div className="flex flex-col items-center">
                      <h2 className="p-2">Busy</h2>
                    </div>
                  </button>
                ) : (
                  <button
                    disabled
                    className="bg-gray-100 w-full rounded-xl text-gray-400 text-xs lg:text-sm shadow object-contain p-1"
                  >
                    <div className="flex flex-col items-center">
                      <h4>Call</h4>
                      <div className="flex">
                        <h4 className="line-through">
                          {(user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.actualCallRate?.usd
                              : data?.data?.actualCallRate?.inr) +
                            ","}
                        </h4>{" "}
                        <h4 className="ml-1">
                          {(user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.callRate?.usd
                              : data?.data?.callRate?.inr) +
                            "/Min"}
                        </h4>
                      </div>
                    </div>
                  </button>
                ))}
                <div className="w-2 h-vh"></div>
                {data?.data?.isConnectModeChat && (data?.data?.user === astrologerId ? (
                  <button
                    disabled
                    className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-sm shadow object-contain p-1"
                  >
                    <div className="flex flex-col items-center">
                      <h2 className="p-2">Busy</h2>
                    </div>
                  </button>
                ) : data?.data?.status == 1 ? (
                  <button
                    className="bg-[#87BD36] w-full rounded-xl text-white text-xs lg:text-sm shadow object-contain p-1"
                    onClick={async() => {
                      if (user?.name !== undefined) {
                        gtmCustomEvent("call_chat");
                        let s = await initiateChat(data?.data?.user, data?.data?.imgUrl);
                        if (s != 1) {
                          setStatus(data?.data?.user, s);
                        }
                      } else {
                        setshowLogin(true);
                      }
                    }}
                  >
                    <div className="flex flex-col items-center">
                      <h4>Chat</h4>
                      <div className="flex">
                        <h4 className="line-through">
                          {(user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.actualCallRate?.usd
                              : data?.data?.actualCallRate?.inr) +
                            ","}
                        </h4>{" "}
                        <h4 className="ml-1">
                          {(user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.callRate?.usd
                              : data?.data?.callRate?.inr) +
                            "/Min"}
                        </h4>
                      </div>
                    </div>
                  </button>
                ) : data?.data?.status == 2 ? (
                  <button
                    disabled
                    className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-sm shadow object-contain p-1"
                  >
                    <div className="flex flex-col items-center">
                      <h2 className="p-2">Busy</h2>
                    </div>
                  </button>
                ) : (
                  <button
                    disabled
                    className="bg-gray-100 w-full rounded-xl text-gray-400 text-xs lg:text-sm shadow object-contain p-1"
                  >
                    <div className="flex flex-col items-center">
                      <h4>Chat</h4>
                      <div className="flex">
                        <h4 className="line-through">
                          {(user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.actualCallRate?.usd
                              : data?.data?.actualCallRate?.inr) +
                            ","}
                        </h4>{" "}
                        <h4 className="ml-1">
                          {(user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.callRate?.usd
                              : data?.data?.callRate?.inr) +
                            "/Min"}
                        </h4>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : data?.widgetType === 2 ? (
        <div className="w-11/12 lg:w-10/12 flex flex-row lg:flex-col py-0 md:py-[6rem] mb-4 rounded-xl overflow-hidden bg-[#FFEEEF] mx-4 shadow-lg">
          <img
            loading="lazy"
            className="object-cover h-40 w-full"
            src={data?.data?.bannerImageUrl}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AstroCard;
